import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { CustomerListComponent } from './list/customer-list.component'
import { ClientListComponent } from './client-list/client-list.component'
import { ClientDetailsComponent } from './client-details/client-details.component'
import {ManageCompanyComponent} from './manage-company/manage-company.component'

const routes: Routes = [
	{
		path: '',
		children: [
			{
				path: '',
				component: CustomerListComponent,
			},
			// {
			// 	path:'clientList',
			// 	component:ClientListComponent,
			// },
			// {
			// 	path:'clientDetails',
			// 	component:ClientDetailsComponent,
			// },
			{
				path:'Empresas',
				component: ManageCompanyComponent,
			}
		],
		
	},
]
@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class CustomerRoutingModule {}
