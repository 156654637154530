import { environment } from "./src/environments/environment";

const authConfing = {
  clientId: environment.auth.clientID.indexOf('#{') > -1 ? 'zyeLBJxh1C8ofhZYJPHZSlOpSklQKbXt' : environment.auth.clientID,
  domain: environment.auth.domain.indexOf('#{') > -1 ? 'mardom-test.auth0.com' : environment.auth.domain,
  connection:environment.auth.connection.indexOf('#{') > -1 ? 'MardomGo' : environment.auth.connection,
  audience: environment.auth.audience.indexOf('#{') > -1 ? 'go.mardom.com' : environment.auth.audience,
  scope: 'openid profile email create:employee-account read:screen create:audit read:audit read:employee-account read:eikon-employees read:customer-account update:customer-account create:assign-role-user read:client read:role create:permission-to-role delete:permission-to-role read:permission-to-role read:permission delete:role-user update:employee-account read:client read:request-type create:request-type update:request-type read:responsible read:responsible-request-type create:responsible-request-type update:responsible-request-type read:lines create:lines update:lines read:request-type-line create:request-type-line update:request-type-line read:responsible-line create:responsible-line update:responsible-line delete:responsible-line read:responsible-customer create:responsible-customer update:responsible-customer read:role-user create:assign-permission-user delete:permission-user crud:trackandtrace', 
  redirectUri: environment.auth.redirectUri.indexOf('#{') > -1 ? 'https://go-admin.qa.mardom.com' : environment.auth.redirectUri
};
export default authConfing;
