import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core'
import { AlertService } from '../../../../../core/services/alert.service'
import { Router } from '@angular/router'
import { customerMardomGoListHeader } from '../../../models/customerMardomGoHeader'
import { CustomerMardomgoService } from '../../../services/customer.service'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { config } from '../../../../../../environments/environment'
import { AssociateCustomerComponent } from '../associateCustomer/associate-customer.component'
import { customerWizardComponent } from '../customerWizard/customer-wizard.component'
import { customerWizardComponentDelete } from '../customerWizardDelete/customer-wizard-delete.component'
import { LogService } from '../../../services/log.service'
import { Screen } from '../../../models/enum/screen'
import { Action } from '../../../models/enum/action'
import { Log } from '../../../models/log'
import { DatashareService} from '../../../services/datashare.service'
import { NgxSpinnerService } from 'ngx-spinner'
@Component({
	selector: 'app-customer',
	templateUrl: './customer-list.component.html',
})
export class CustomerListComponent implements OnInit {
	@ViewChild('conformclientstatus', { static: true }) conformclientstatus: TemplateRef<any>;
	page = 0
	filter: any = {}
	dataPage: any = {}
	message: string
	listPage = []
	listFilters = []
	loading = false
	public clientid = ''
	public clientstatustext = ''
	public clientstatus = ''
	public clientstatusbtn = ''
	filterValue = ''
	selectedFilter = ''
	mardomGoCustomers: customerMardomGoListHeader = new customerMardomGoListHeader()

	constructor(
		private alertService: AlertService,
		private _modalService: NgbModal,
		private router: Router,
		private customerService: CustomerMardomgoService,
	    private modalService: NgbModal,
		private logService: LogService,
		private datashareService:DatashareService,
		private ngxSpinnerService:NgxSpinnerService,
	) {}

	ngOnInit() {
		this.mardomGoCustomers.users = []
		this.filter.active = null
		this.getAll(false)
		this.getFilterCriteria()
	}
	// deleteClientCompany(item: any): void {
	// 	this.syncAuthUser(item);
	// 	let modal = this._modalService.open(
	// 		customerWizardComponentDelete,
	// 		config.modalConfig
	// 	)
	// 	modal.componentInstance.userId = item.id;
	// 	modal.componentInstance.customerId = item.clientId
	// 	modal.componentInstance.customerName = item.asociatedCompanyName
	// 	modal.componentInstance.customer = item
	// }
	// associateCustomer(item: any): void {
	// 	this.syncAuthUser(item);
	// 	let modal = this._modalService.open(
	// 		customerWizardComponent,
	// 		config.modalConfig
	// 	)		
	// 	modal.componentInstance.customerId = item.clientId
	// 	modal.componentInstance.customerName = item.asociatedCompanyName
	// 	modal.componentInstance.customer = item
	// }
	changeclientstatuspop(item:any, status:any){
		this.clientid = ""
		this.clientstatustext = ""
		this.clientstatus = ""
		this.clientstatusbtn = ""
		if(status == false){
			this.clientstatustext = "Seguro que desactivas al usuario"
			this.clientstatusbtn = "Desactivas"
		}else if(status == true){
			this.clientstatustext = "Seguro que activas al usuario"
			this.clientstatusbtn = "Activas"
		}
		this.clientid = item.id
		this.clientstatus = status
		this.modalService.open(this.conformclientstatus, {
			size: 'md', backdrop: 'static', centered: true
		});
	  
	}
	changeclientstatus(){
		this.customerService.changeclientstatus(this.clientid, this.clientstatus).subscribe((response)=>{
			this.close()	
			console.log(response)
			this.getAll(false)
		})
	}
	close(){
		this.modalService.dismissAll()
	}
	getFilterCriteria() {
		this.listFilters = [
			{ id: 'email', value: 'Email' },
			{ id: 'name', value: 'Nombre' },
		]
	}
	confirmRemoveCustomer(item: any) {
		this.alertService.question(() => {
			this.removeCustomer(item)
		}, `¿Está seguro que desea inactivar a ${item.fullName} de la empresa ${item.asociatedCompanyName}`)
	}
	removeCustomer(item: any) {
		let sendToServer = {
			id: item.id,
			active: false,
			clientId: item.clientId,
		}
		this.customerService.unactivateCustomer(sendToServer).subscribe(
			(response) => {
				this.alertService.success('Usuario inactivado exitosamente')
				this.logService.post(<Log>{
					ScreenId: Screen.EnlaceClienteEmpresa,
					ActionId:Action.InactivacionCuenta,							
					Json: JSON.stringify(sendToServer),
				})
				this.getAll(false)
			},
			(error) => {
				if (error.status == 403 || error.status == 401)
					this.alertService.error('No tiene permisos para realizar la acción')
				this.alertService.error(error.error.errors[0].message)
			}
		)
	}
	clean() {
		this.filterValue = ''
		this.selectedFilter = null
		this.filter.active = null
		this.getAll(false)
	}
	getAll(resetPage: boolean) {
		this.loading = true
		this.filter.name = this.selectedFilter == 'name' ? this.filterValue : ''
		this.filter.email = this.selectedFilter == 'email' ? this.filterValue : ''
		if (resetPage) this.page = 0
		this.customerService.getPaginated(this.filter, this.page).subscribe(
			(response) => {
				this.loading = false
				if (response.total == 0)
					return this.alertService.error('No hay datos disponibles')
				this.mardomGoCustomers.users = response.users
				this.dataPage = response
				this.dataPage.totalPage = Math.ceil(response.total / response.limit)
			},
			(error) => {
				this.loading = false
				if (error.status == 403 || error.status == 401) {
					this.alertService.error('No tiene permisos para realizar la acción')
					this.router.navigate(['/mardomgoadmin'])
				}
				console.log(error)
				this.alertService.error(error.error.errors[0].message)
				this.mardomGoCustomers.users = []
				this.dataPage = {}
			}
		)
	}
	changePage(next: boolean) {
		this.page = next ? (this.page += 1) : (this.page -= 1)
		if (this.page < 0) this.page = 0
		this.getAll(false)
	}

	assingResponsible(item: any) {
		this.router.navigate([
			`/mardomgoadmin/responsible-customer-create/${item.clientId}/${item.asociatedCompanyName}`,
		])
	}

	getuserdetails(item,type){
		const userDetails=[
			type,
			item.email,
			item.fullName ]
		let roledata =userDetails.join('  ');
		sessionStorage.setItem('typeofRole',roledata);
		this.datashareService.setcustomerDetails(item);
		this.router.navigate([
			`/mardomgoadmin/clientList`,
		])
	}

	getmanagecompany(item){
		this.datashareService.setcustomerDetails(item);
		this.router.navigate([`/mardomgoadmin/customer/Empresas`])
	}

	syncAuthUser(item){
		console.log(item);
		let payload=
		[{ "IdAuth0":item.id,
			"Name":item.firstName,
			"email":item.email,
			"LastName":item.lastName,
			"External":true 
		}]

		if(item.syncstatus==false){
			console.log(payload)
			this.customerService.syncAuthUser(payload).subscribe((response)=>{
			},(error)=>{})
		}
	}

	
}


