import { Component, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { customerMardomGo } from '../../../models/customerMardomGo';
import { ResponsiblesService } from '../../../services/responsiblesServices.service';
import { AlertService } from '../../../../../../../src/app/core/services/alert.service';
import { CustomerMardomgoService } from '../../../services/customer.service';
import { EventEmitter } from 'events';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2'
import { LogService } from '../../../services/log.service';
import { Log } from '../../../models/log';
import { Screen } from '../../../models/enum/screen';
import { Action } from '../../../models/enum/action';
import {assignToCustomer} from '../../../models/assignToCustomer'
import {DatashareService} from '../../../services/datashare.service';

@Component({
  selector: 'customer-wizard-delete',
  styles: [`
    .mat-stepper-horizontal {
        margin-top: 8px;
    }
    
    .mat-form-field {
        margin-top: 16px;
    }
  `],
  templateUrl: './customer-wizard-delete.component.html',
})
export class customerWizardComponentDelete {
  isLinear = false;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  customerId: string;
  userId: String;
  customerName: string;
  customer: customerMardomGo = new customerMardomGo()
  ResponsibleCustomer: any
	selectedCompany: any = [];
  responsableName: string
  customerInfo: any;
  accountExecutive:any;
  @Output() notifyParent: EventEmitter = new EventEmitter()
  assignToCustomer: any;
  companyId:string;
  selectedcompany:any;
	title = ''

  constructor(private _formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
    private responsibleService: ResponsiblesService,
    private alertService: AlertService,
    private customerService: CustomerMardomgoService,
    private spinnerService: NgxSpinnerService,
    private logService : LogService,
  ) { }
  ngOnInit() {   
    this.spinnerService.show();
    this.logService.post(<Log>{
			ScreenId : Screen.EnlaceClienteEmpresa,
			ActionId : Action.AccederAPantalla,
			Json : ''
		  });
    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required]
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ['', Validators.required]
    });
		this.title = this.customer.clientId ? 'Modificar empresa enlazada a usuario' : 'Enlazar empresa a usuario';
    this.loadCompany()
  }
  close() {
    this.activeModal.close()
  }

  async loadCompany() {
		let getCompany: any = this.customer
		let ClientIds:any = []
		getCompany.userAssociatedCompanies.forEach(element => {
			ClientIds.push(element.clientid);
		});

		if (ClientIds.length != 0) {
			this.customerService
				.getCompanyById({ClientIds})
				.subscribe((response) => {
					this.selectedCompany = response
          this.spinnerService.hide();
				})
    }else{
      this.spinnerService.hide();
    }
	}

  deleteclientcompany(company:any){
    let test = {
      userId: this.userId,
      Companies: [company.id]
    }
    
    if(this.selectedCompany.length > 1){
      Swal.fire({
        title: '¿Quieres desvincular esta empresa?',
        text: ``,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirmado',
      }).then((result) => {
        if(result.value){
          this.spinnerService.show();
          this.customerService.deletecompany(test).subscribe((response)=>{
            this.close()
            window.location.reload()
          })
        }
      })
    }else{
      Swal.fire({
        title: 'No puedes desvincular todas las empresas.',
        text: ``,
        icon: 'warning',
        showConfirmButton: true,
        showCancelButton: false,
        confirmButtonText: 'OK !',
      })
    }
  }

  async syncAuthUser(){
    // console.log(this.customerInfo.assignCompanyList[0]);
     if(this.customer.syncstatus==false){
      let payload=[{
      "IdAuth0":this.customerInfo.assignCompanyList[0].id,
			"Name":this.customerInfo.assignCompanyList[0].firstName,
			"email":this.customerInfo.assignCompanyList[0].email,
      "LastName":this.customerInfo.assignCompanyList[0].lastName,
			"External":true }]
      await this.customerService.syncAuthUser(payload).subscribe((response)=>{
     },(error)=>{
      console.log(error)
     })
    }}
}
