import { Component, OnInit, ViewChild, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '../../../../../core/services/alert.service';
import { ResponsiblesService } from '../../../services/responsiblesServices.service';
import { Router } from '@angular/router';
import { assingResponsibleCustomer } from '../../../models/assingReponsibleCustomer';
import { NgxSpinnerService } from 'ngx-spinner';
import { ResponsibleRequestTypeLine } from '../../../models/responsibleRequestTypeLine';
import { EventEmitter } from '@angular/core';
import {ResponsibleCustomer} from '../../../models/responsibleCustomer'
import { assignToCustomer } from '../../../models/assignToCustomer';
import { accountExecutive } from '../../../models/accountExecutive';


@Component({
  selector: 'kt-responsible-customer-add',
  templateUrl: './responsible-customer-add.component.html',
  styleUrls: ['./responsible-customer-add.component.scss']
})
export class ResponsibleCustomerAddComponent implements OnInit {
  responsibleFormGroup: FormGroup;
  ResponsibleCustomer: assingResponsibleCustomer = new assingResponsibleCustomer();
  responsibleRequestType: ResponsibleRequestTypeLine[];
  @Input() customerId: any = '';
  @Output() notifyParent: EventEmitter<any> = new EventEmitter();
  selectedFilter = '';
  mainResponsable: boolean = false;
  responsibleRequestTypeId: string = "#";

  accountExecutives: accountExecutive= new accountExecutive();
  responsibleCustomer:ResponsibleCustomer[]
  accountExecutive: accountExecutive[];
  accountExecutiveId: string = "#";
  companyId:string;
  assignToCustomer: assignToCustomer=new assignToCustomer();
  type:string;
  responsibleName:string;

  constructor(private formsBuilder: FormBuilder,
    private router: Router,
    private responsibleService: ResponsiblesService,
    private alertService: AlertService,
    private spinnerService: NgxSpinnerService,
  ) { }

  ngOnInit() {   
    this.getResponsibleRequestType();
    this.getResponsibleUser();
  }

  getResponsibleUser(){
    this.responsibleService.getResponsibleUser().subscribe(
      (list:any) => {
        this.accountExecutive=list
      },
      (error) => {
        this.spinnerService.hide()
        switch (error.status) {
          case 401:
            return this.alertService.error(
              'No tiene los permisos para consultar este recurso').then((result) => { if (result.value) { this.router.navigate(["/mardomgoadmin/dashboard"]) } })
          case 403:
            return this.alertService.error(
              'No tiene los permisos para consultar este recurso').then((result) => { if (result.value) { this.router.navigate(["/mardomgoadmin/dashboard"]) } })
          case 500:
            return this.alertService.error(
              'Oop! Ha ocurrido un error').then((result) => { if (result.value) { this.router.navigate(["/mardomgoadmin/dashboard"]) } })
          case 0:
            return this.alertService.error(
              'Oops! Ha ocurrido un error.', 'Error').then((result) => { if (result.value) { this.router.navigate(["/mardomgoadmin/dashboard"]) } })
          case undefined:
            return this.alertService.error(
              'Oops! Ha ocurrido un error.', 'Error').then((result) => { if (result.value) { this.router.navigate(["/mardomgoadmin/dashboard"]) } })
        }
      }
      );
  }


  getResponsibleRequestType() {
    this.spinnerService.show()
    this.responsibleService.getResponsibleRequestType().subscribe(
      (list) => {
        this.responsibleRequestType = list
        this.spinnerService.hide()
      },
      (error) => {
        this.spinnerService.hide()
        switch (error.status) {
          case 401:
            return this.alertService.error(
              'No tiene los permisos para consultar este recurso').then((result) => { if (result.value) { this.router.navigate(["/mardomgoadmin/dashboard"]) } })
          case 403:
            return this.alertService.error(
              'No tiene los permisos para consultar este recurso').then((result) => { if (result.value) { this.router.navigate(["/mardomgoadmin/dashboard"]) } })
          case 500:
            return this.alertService.error(
              'Oop! Ha ocurrido un error').then((result) => { if (result.value) { this.router.navigate(["/mardomgoadmin/dashboard"]) } })
          case 0:
            return this.alertService.error(
              'Oops! Ha ocurrido un error.', 'Error').then((result) => { if (result.value) { this.router.navigate(["/mardomgoadmin/dashboard"]) } })
          case undefined:
            return this.alertService.error(
              'Oops! Ha ocurrido un error.', 'Error').then((result) => { if (result.value) { this.router.navigate(["/mardomgoadmin/dashboard"]) } })
        }
      }
    );
  }


  assignResponsibleToCustomer() {    
    let assingResponsibleCustomerDto = []
    // this.customerId.assignCompanyList.forEach(element => {
      let data1 = {
        customerId:this.customerId,
        active:true,
        mainResponsible:false,
        responsibleRequestTypeId:this.responsibleRequestTypeId
      }
      assingResponsibleCustomerDto.push(data1)
      
    // }); 
    this.ResponsibleCustomer.customerId = this.customerId;
    this.ResponsibleCustomer.active = true;
    this.ResponsibleCustomer.mainResponsible = this.mainResponsable
    this.ResponsibleCustomer.responsibleRequestTypeId = this.responsibleRequestTypeId
     if(this.type=='responsibleRequestType'){
      let responsable =this.responsibleRequestType.find(item => item.id == this.responsibleRequestTypeId);
      this.responsibleName=responsable.responsibleName;
     }   

    this.assignToCustomer.customerId=this.customerId;
    this.assignToCustomer.active=true;
    this.assignToCustomer.executiveid=this.accountExecutiveId;

    const data = {
      responsibleName: this.responsibleName,
      ResponsibleCustomer: {assingResponsibleCustomerDto},
      assignToCustomer:this.assignToCustomer
    }

    this.notifyParent.emit(data);
  }

  onChangeDDL(item:any,type){
    this.type=type;
    this.assignResponsibleToCustomer();
  }

  changeCheckbox(item:any){
    this.assignResponsibleToCustomer();
  }

}