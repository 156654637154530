import { Component, Output,Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { customerMardomGo } from '../../../models/customerMardomGo';
import { ResponsiblesService } from '../../../services/responsiblesServices.service';
import { AlertService } from '../../../../../core/services/alert.service';
import { CustomerMardomgoService } from '../../../services/customer.service';
import { EventEmitter } from 'events';
import { NgxSpinnerService } from 'ngx-spinner';
import { LogService } from '../../../services/log.service';
import { Log } from '../../../models/log';
import { Screen } from '../../../models/enum/screen';
import { Action } from '../../../models/enum/action';
import {assignToCustomer} from '../../../models/assignToCustomer'
import {DatashareService} from '../../../services/datashare.service';

@Component({
  selector: 'empresa-wizard',
  styles: [`
    .mat-stepper-horizontal {
        margin-top: 8px;
    }
    
    .mat-form-field {
        margin-top: 16px;
    }
  `],
  templateUrl: './empresa-wizard.component.html',
})
export class empresaWizardComponent {
  isLinear = false;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  customer: customerMardomGo = new customerMardomGo()
  ResponsibleCustomer: any
  responsableName: string
  customerInfo: any;
  accountExecutive:any;
  @Input() public customerId;
  @Output() notifyParent: EventEmitter = new EventEmitter()
  assignToCustomer: any;
  companyId:string;
  selectedcompany:any;

  constructor(private _formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
    private responsibleService: ResponsiblesService,
    private alertService: AlertService,
    private spinnerService: NgxSpinnerService,
    private logService : LogService,
  ) { }
  ngOnInit() {   
    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required]
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ['', Validators.required]
    });
  }
  close() {
    this.activeModal.close()
  }

 
  saveResponsible() {
    this.responsibleService.assignResponsibleToCustomer(this.ResponsibleCustomer)
      .subscribe(
        () => {
          this.spinnerService.hide();
          this.logService.post(<Log>{
            ScreenId : Screen.EnlaceClienteEmpresa,
            ActionId : Action.AsignacionDeResponsableACliente,
            Json : JSON.stringify(this.customerInfo)
            });
          this.alertService.success("Información guardada exitosamente.").then(() => {
            window.location.reload()
          })
        },
        (error) => {
          this.spinnerService.hide();
          if (error.status == 403 || error.status == 401) {
            this.alertService.error('No tiene permisos para realizar la acción')
            return;
          }
          if (error.status == 400) {
            this.alertService.warning(error.error.errors[0].message);
            return;
          }
          try {
            this.alertService.error(error.error.errors[0].message);
          }
          catch {
            this.alertService.error('Ha ocurrido un error al realizar dicha acción');
          }
        });
  }

  getDataResponsible(data: any) {
    this.responsableName = data.responsibleName;
    this.ResponsibleCustomer = data.ResponsibleCustomer;
    this.assignToCustomer=data.assignToCustomer;
  }
}
