import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { Routes, RouterModule } from '@angular/router'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { CustomerListComponent } from './list/customer-list.component'
import { CustomerMardomgoService } from '../../services/customer.service'
import { CustomerRoutingModule } from './customer.route'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { AssociateCustomerComponent } from './associateCustomer/associate-customer.component'
import { customerWizardComponent } from './customerWizard/customer-wizard.component'
import { customerWizardComponentDelete } from './customerWizardDelete/customer-wizard-delete.component'
import { MatStepperModule, MatLabel, MatListModule, MatIconModule, MatFormFieldModule, MatInputModule } from '@angular/material'
import { ResponsibleCustomerCreateComponent } from '../responsible-customer/add/responsible-customer-create.component'
import { NgxSpinnerModule } from 'ngx-spinner'
import { LogService } from '../../services/log.service';
import { ClientListComponent } from './client-list/client-list.component';
import { ClientDetailsComponent } from './client-details/client-details.component'
import { HorizontalStepperComponent } from './horizontal-stepper/horizontal-stepper.component';
import { AssociateEmpresaComponent } from './associate-empresa/associate-empresa.component';
import { AssignRolComponent } from './assign-rol/assign-rol.component';
import { ManageCompanyComponent } from './manage-company/manage-company.component';
import { ResponsibleCustomerAddComponent } from '../responsible-customer/addSelect/responsible-customer-add.component'
import { empresaWizardComponent } from '../customer/empresaWizard/empresa-wizard.component'
import { GestionEmpresasComponent } from '../customer/gestion-empresas/gestion-empresas.component';


@NgModule({
	imports: [
        NgbModule, 
        CommonModule,
        FormsModule, 
        CustomerRoutingModule,
        MatListModule,
        MatIconModule,
        MatStepperModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        MatInputModule,
        NgxSpinnerModule       
    ],
	declarations: [
        CustomerListComponent,
        AssociateCustomerComponent,
        customerWizardComponent,
        customerWizardComponentDelete,
        ResponsibleCustomerCreateComponent,
        ClientListComponent,
        ClientDetailsComponent,
        HorizontalStepperComponent,
        AssociateEmpresaComponent,
        AssignRolComponent,
        ManageCompanyComponent,
        empresaWizardComponent,
        ResponsibleCustomerAddComponent,
        GestionEmpresasComponent
        
    ],
	providers: [
        CustomerMardomgoService,
        LogService
    ],
	exports: [
        RouterModule
    ],
    entryComponents:[
        AssociateCustomerComponent,
        customerWizardComponent,
        customerWizardComponentDelete,
        ResponsibleCustomerCreateComponent,
        HorizontalStepperComponent,
        empresaWizardComponent,
        ResponsibleCustomerAddComponent

    ]
})
export class CustomerModule {}

