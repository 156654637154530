import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from '../../../../../core/services/alert.service';
import Swal from 'sweetalert2';
import { config } from '../../../../../../environments/environment'
import { customerMardomGo } from '../../../models/customerMardomGo';
import { CustomerMardomgoService } from '../../../services/customer.service';
import { DatashareService } from '../../../services/datashare.service';
import { customerWizardComponent } from '../customerWizard/customer-wizard.component';


@Component({
  selector: 'kt-manage-company',
  templateUrl: './manage-company.component.html',
  styleUrls: ['./manage-company.component.scss']
})
export class ManageCompanyComponent implements OnInit {
  public comapanymanagelist: any = []
  CustomerDetails: any;
  userlist: any = [];
  public clientidtest = ''
  public clientid = ''
  userId: String;
  selectedCompany: any = [];
  selectedcompany: any;
  customer: customerMardomGo = new customerMardomGo()

  constructor(
    private router: Router,
    private _modalService: NgbModal,
    private datashareService: DatashareService,
    private customerService: CustomerMardomgoService,
    private alertService: AlertService,
    private ngxSpinnerService: NgxSpinnerService,
    private spinnerService: NgxSpinnerService,
  ) { }

  ngOnInit() {
    this.CustomerDetails = this.datashareService.customerDetails;
    if (!this.CustomerDetails) {
      return this.router.navigate(["/mardomgoadmin/customer"])
    } else {
      this.getmanagecompanylist();
    }
    this.spinnerService.show();
  }

  associateCustomer(customer: any): void {
    this.syncAuthUser(this.CustomerDetails);
    let modal = this._modalService.open(
      customerWizardComponent,
      config.modalConfig
    )
    modal.componentInstance.customerId = this.CustomerDetails.id
    modal.componentInstance.customerName = this.CustomerDetails.asociatedCompanyName
    modal.componentInstance.customer = this.CustomerDetails
    modal.componentInstance.clientidtest = this.clientidtest

  }

  syncAuthUser(item) {
    console.log(item);
    let payload =
      [{
        "IdAuth0": item.id,
        "Name": item.firstName,
        "email": item.email,
        "LastName": item.lastName,
        "External": true
      }]

    if (item.syncstatus == false) {
      console.log(payload)
      this.customerService.syncAuthUser(payload).subscribe((response) => {
      }, (error) => { })
    }
  }


  callApi() {

    this.customerService.getCompany('7', 'accepted').subscribe(
      (response) => {
        //		this.loading = false
        console.log(response)
      },
      (error) => {
        //		this.loading = false
        console.log(error)
      }
    )
    let payload = {
      Email: 'siddha1@Mardom.com',
      Empresa: 'Mardom',
    }

    this.customerService.sendMail(payload).subscribe(response => {
      console.log(response);
    }
      , (error) => {
        console.log(error);
      })
  }

  sendMail() {
    let payload = {
      Email: 'siddha1@Mardom.com',
      Empresa: 'Mardom',
    }

    this.customerService.sendMail(payload).subscribe(response => {
      console.log(response);
    }
      , (error) => {
        console.log(error);

      })
  }

  getmanagecompanylist() {
    this.ngxSpinnerService.show();
    this.userId = this.CustomerDetails.id;
    let ClientIds: any = []

    this.customerService.GetUserlistUserId(this.userId).subscribe((response) => {
      this.userlist = response;
      if (this.userlist) {
        this.userlist.forEach(element => {
          ClientIds.push(element.companyId);
        })
      } else {
        this.alertService.warning('Lo sentimos,No se ha encontrado ninguna empresa');
      };
      this.clientidtest = ClientIds
      console.log(ClientIds)
      this.ngxSpinnerService.hide();
    }, (error) => {
      this.alertService.error('Ha ocurrido un error, favor intente de nuevo.');
      this.ngxSpinnerService.hide();
    })
  }

  async loadCompany() {
    let getCompany: any = this.customer
    let ClientIds: any = []
    getCompany.userAssociatedCompanies.forEach(element => {
      ClientIds.push(element.clientid);
    });

    if (ClientIds.length != 0) {
      this.customerService
        .getCompanyById({ ClientIds })
        .subscribe((response) => {
          this.selectedCompany = response
          this.spinnerService.hide();
        })
    } else {
      this.spinnerService.hide();
    }
  }

  deleteClientCompany(company: any) {
    let user = {
      userId: this.userId,
      Companies: [company.companyId]
    }
    // console.log(user)
    // console.log(company)
    if (this.userlist.length > 1) {
      Swal.fire({
        title: '¿Quieres desvincular esta empresa?',
        text: ``,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirmado',
      }).then((result) => {
        if (result.value) {
          this.spinnerService.show();
          this.customerService.deletecompany(user).subscribe((response) => {
            // this.spinnerService.hide();
            this.getmanagecompanylist();
            // window.location.reload()
          })
        }
      })
    } else {
      Swal.fire({
        title: 'No puedes desvincular todas las empresas.',
        text: ``,
        icon: 'warning',
        showConfirmButton: true,
        showCancelButton: false,
        confirmButtonText: 'OK !',
      })
    }
  }
}
