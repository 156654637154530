import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { endpoint } from '../../../../environments/environment'
import { customerMardomGoListHeader } from '../models/customerMardomGoHeader'
import { CorsHeaders } from '../../../core/services/corsHeader'

@Injectable()
export class CustomerMardomgoService {
	constructor(
		private _httpClient: HttpClient,
		private corsHeader: CorsHeaders
	) {}
	getPaginated(filter: any, page: number) {
		return this._httpClient.get<customerMardomGoListHeader>(
			`${endpoint.customer}/paginated?pageSize=15&pageNumber=${page}&name=${filter.name}&email=${filter.email}&active=${filter.active}`,
			{ headers: this.corsHeader.headers() }
		)
	}
	sendMail(payload) {
		return this._httpClient.post<any>(
			`${endpoint.sendMail}email=${payload.Email}&empresa=${payload.Empresa}`,
			{ headers: this.corsHeader.headers() }
		)
	}
	
	deletecompany(clientdata:any){
		const url = `${endpoint.deletecompany}`
		return this._httpClient.post(url,clientdata, {headers: this.corsHeader.headers()})
	}

	savePermissions(id: string, permissions: any[]) {
		return this._httpClient.post<any>(endpoint.sendMail+`/${id}/permissions`, permissions, {headers: this.corsHeader.headers()});
    }
	
	changeclientstatus(id:any, status: any){
		return this._httpClient.get<customerMardomGoListHeader>(
			`${endpoint.syncAuthUser}/updateUserStatus?userId=${id}&active=${status}`,
			{ headers: this.corsHeader.headers() }
		)
	}

	activateCustomer(data: any) {	
		let id = data.assignCompanyList?data.assignCompanyList[0].id:data.id
		return this._httpClient.patch(`${endpoint.customer}/${id}`, data, {
			headers: this.corsHeader.headers(),
		})
	}

	unactivateCustomer(data: any) {	
		return this._httpClient.patch(`${endpoint.inactivecustomer}/${data.id}`, data, {
			headers: this.corsHeader.headers(),
		})
	}
	
	getCompanyByName(name: string) {
		return this._httpClient.get<any[]>(
			`${endpoint.company}/getbyname?name=${name}`,
			{
				headers: this.corsHeader.headers(),
			}
		)
	}

	getCompany(code,status) {
		return this._httpClient.get<any[]>(
			`${endpoint.getCompany}/CompanyCode=${code}&Status=${status}`,
			{
				headers: this.corsHeader.headers(),
			}
		)
	}

	getCompanyById(Id: any) {
		return this._httpClient.post<any[]>(
			`${endpoint.company}/getbyId`,Id,
			{
				headers: this.corsHeader.headers(),
			}
		)
	}

	GetUserlistUserId(userId) {
		return this._httpClient.get<any[]>(`${endpoint.GetUserlistUserId+userId}`,{headers: this.corsHeader.headers(),})
	}



	assignCompanyToUser(payload) {
		return this._httpClient.post<any[]>(`${endpoint.assignCompanyToUser}`,payload,{headers: this.corsHeader.headers(),})
	}

	syncAuthUser(payload){
		return this._httpClient.post<any[]>(`${endpoint.syncAuthUser}`,payload,{headers: this.corsHeader.headers(),})
	}


	getCompanies() {
		return this._httpClient.get<customerMardomGoListHeader>(
			`${endpoint.getCompanies}`,
			{ headers: this.corsHeader.headers() }
		)
	}
}
