export class MenuConfig {
  public defaults: any = {
    header: {
      self: {},
      items: [
        {
          title: 'Inicio',
          root: true,
          alignment: 'left',
          page: 'dashboard',
          translate: 'MENU.DASHBOARD'
        },
        {
          title: 'Menu',
          root: true,
          alignment: 'left',
          toggle: 'click',
          submenu: [
            {
              title: 'Clientes Mardom Go',
              bullet: 'dot',
              icon: 'flaticon-list',
              page: '/mardomgoadmin/customer'
            },
            {
              title: 'Empleados Eikon ',
              bullet: 'dot',
              icon: 'flaticon-users-1',
              page:'/mardomgoadmin/eikon-employees'
            },
            {
              title: 'Ejecutivos Internos MardomGo',           
              bullet: 'dot', 
              icon: 'flaticon-users',
              page:'/mardomgoadmin/internalusers'
            },
            {
              title: 'Responsables',
              bullet: 'dot',
              icon: 'flaticon-user-settings',
              submenu: [
                {
                  title: 'Responsables Tipo Solicitud',
                  bullet: 'dot',     
                  icon: 'flaticon-list',
                  page: '/mardomgoadmin/responsible-request-type',            
                },
                {
                  title: 'Responsables Clientes',
                  bullet: 'dot',     
                  icon: 'flaticon-list',
                  page: '/mardomgoadmin/responsible-customer',            
                }
              ]
            },
            {
              title: 'Log Cambio de Estatus en Solicitudes',           
              bullet: 'dot', 
              icon: 'flaticon-security',
              page:'/mardomgoadmin/request'
            },
            {
              title: 'Log Cambio de Estatus en Comprobantes',           
              bullet: 'dot', 
              icon: 'flaticon2-file-1',
              page:'/mardomgoadmin/voucher'
            },/*  {
              title: 'Empresa',           
              bullet: 'dot', 
              icon: 'flaticon2-file-1',
              page:'/mardomgoadmin/perfil-empresa'
            }, */
            {
              title: 'Gestionar Empresas',           
              bullet: 'dot', 
              icon: 'flaticon-users-1',
              page:'/mardomgoadmin/gestionEmpresa'
            }
           
          ]
        },      
      ]
    },
    aside: {
      self: {},
      items: [
        {
          title: 'Dashboard',
          root: true,
          icon: 'flaticon2-architecture-and-city',
          page: '/dashboard',
          translate: 'MENU.DASHBOARD',
          bullet: 'dot'
        },
        {
          title: 'Layout Builder',
          root: true,
          icon: 'flaticon2-expand',
          page: '/builder'
        },
        { section: 'Components' },
        {
          title: 'Google Material',
          root: true,
          bullet: 'dot',
          icon: 'flaticon2-browser-2',
          submenu: [
            {
              title: 'Form Controls',
              bullet: 'dot',
              submenu: [
                {
                  title: 'Auto Complete',
                  page: '/material/form-controls/autocomplete',
                  permission: 'accessToECommerceModule'
                }
              ]
            }
          ]
        }
        
      ]
    }
  };

  public get configs(): any {
    return this.defaults;
  }
}
