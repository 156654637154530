import { Component, OnInit,	Output,
	EventEmitter,
	Input,
	ChangeDetectorRef, } from '@angular/core';
import { CustomerMardomgoService } from '../../../services/customer.service'
import { AlertService } from '../../../../../core/services/alert.service'
import { Router } from '@angular/router'
import { Companies } from '../../../models/companies'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { empresaWizardComponent } from '../empresaWizard/empresa-wizard.component';
import { customerWizardComponent } from '../customerWizard/customer-wizard.component'
import { config } from '../../../../../../environments/environment'

@Component({
  selector: 'kt-gestion-empresas',
  templateUrl: './gestion-empresas.component.html',
  styleUrls: ['./gestion-empresas.component.scss']
})
export class GestionEmpresasComponent implements OnInit {
  loading = false
  page = 0
  empresaDetails: Companies;
	selectedFilter = 0
  filterValue = ''
  filter: any = {}
	@Output() notifyParent: EventEmitter<any> = new EventEmitter()

  constructor(
    private customerService: CustomerMardomgoService,
    private alertService: AlertService,
    private router: Router,
    private _modalService: NgbModal,

  ) { }

  ngOnInit() {
    this.getAllCompanies();
  }

  getAllCompanies() {
    this.loading = true
    this.customerService.getCompanies().subscribe(
      (response:any) => {
        this.loading = false
        this.empresaDetails = response.clients;
        console.log(this.empresaDetails)

      },
      (error) => {
        this.loading = false
        if (error.status == 403 || error.status == 401) {
          this.alertService.error('No tiene permisos para realizar la acción')
          this.router.navigate(['/mardomgoadmin'])
        }
        console.log(error)
      }
    )
  }

  clean() {
		this.filterValue = ''
		this.selectedFilter = null
		this.filter.active = null
		this.getAllCompanies()
	}

  associateCustomer(CustomerId): void {
		let modal = this._modalService.open(
			empresaWizardComponent,
			config.modalConfig
		)		
		 modal.componentInstance.customerId = CustomerId
    //  console.log( modal.componentInstance.customerId)
		// modal.componentInstance.customerName = item.asociatedCompanyName
		// modal.componentInstance.customer = item
   this.associateCompany(CustomerId);
	}
  associateCompany(CustomerId) {
		const dataToSend = {
			CustomerId: {CustomerId},
		}
		this.notifyParent.emit(dataToSend)
	}

}
