import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddRoleToUserComponent } from '../../../../app/features/mardom-go-admin/components/add-role-to-user/add-role-to-user.component';
import { HomeComponent } from '../components/home/home.component';
import { BaseComponent } from '../../../views/theme/base/base.component';
import { InternalUsersComponent } from '../components/internal-users/list-internal-users/internal-users.component';
import { DetailsInternalUsersComponent } from '../components/internal-users/details-internal-users/details-internal-users.component';
import { EikonEmployeesComponent } from '../components/eikon-employees/eikon-employees.component';
import { CustomerListComponent } from '../components/customer/list/customer-list.component';
import { ResponsibleRequestTypeComponent } from '../components/responsible-request-type/list/responsible-request-type.component';
import { ResponsibleRequestTypeCreateComponent } from '../components/responsible-request-type/add/responsible-request-type-create.component';
import { ResponsibleRequestTypeEditComponent } from '../components/responsible-request-type/edit/responsible-request-type-edit.component';
import { ResponsibleCustomerComponent } from '../components/responsible-customer/list/responsible-customer.component';
import { ResponsibleCustomerCreateComponent } from '../components/responsible-customer/add/responsible-customer-create.component';
import { ResponsibleCustomerEditComponent } from '../components/responsible-customer/edit/responsible-customer-edit.component';
import { ModuleGuard } from '../../../../app/core/auth/_guards/permission.guard';
import { PerfilEmpresaComponent } from '../components/Empresas/perfil-empresa/perfil-empresa.component';
//import { AllCompaniesListComponent } from '../components/Empresas/all-companies-list/all-companies-list.component';


import { ClientListComponent } from '../../mardom-go-admin/components/customer/client-list/client-list.component'
import { ClientDetailsComponent } from '../../mardom-go-admin/components/customer/client-details/client-details.component'
import { GestionEmpresasComponent } from '../../mardom-go-admin/components/customer/gestion-empresas/gestion-empresas.component';


const routes: Routes = [
	{
		path: '',
		component: BaseComponent,
		children: [
			{
				path: 'dashboard',
				component: HomeComponent
			},
			{
				path: 'eikon-employees',
				pathMatch: 'full',
				component: EikonEmployeesComponent
			},
			{
				path: 'eikon',
				pathMatch: 'full',
				component: AddRoleToUserComponent
			},
			{
				path: 'add-role',
				component: AddRoleToUserComponent
			},
			{
				path: 'internalusers',
				component: InternalUsersComponent
			},
			{
				path: 'detailsinternalusers/:id',
				component: DetailsInternalUsersComponent, pathMatch: 'full'
			},
			{
				path: 'responsible-request-type',
				pathMatch: 'full',
				component: ResponsibleRequestTypeComponent
			},
			{
				path: 'responsible-request-type-create',
				pathMatch: 'full',
				component: ResponsibleRequestTypeCreateComponent
			},
			{
				path: 'responsible-request-type-edit/:id',
				pathMatch: 'full',
				component: ResponsibleRequestTypeEditComponent
			},			
			{
				path: 'responsible-customer',
				pathMatch: 'full',
				component: ResponsibleCustomerComponent
			},
			{
				path: 'responsible-customer-create/:customerId/:customerName',
				pathMatch: 'full',
				component: ResponsibleCustomerCreateComponent
			},
			{
				path: 'responsible-customer-edit/:id',
				pathMatch: 'full',
				component: ResponsibleCustomerEditComponent
			},
			{
				path:'clientList',
				component:ClientListComponent,
			},
			{
				path:'gestionEmpresa',
				component:GestionEmpresasComponent,
			},
			{
				path:'clientDetails',
				component:ClientDetailsComponent,
			},
			{
				path: 'customer',
				loadChildren: () =>
					import(
						'../components/customer/customer.module'
					).then((m) => m.CustomerModule),
			},{
				path: 'request',			
				loadChildren: () =>
					import(
						'../components/request-log/request-log.module'
					).then((m) => m.RequestLogModule),
			},{
				path: 'voucher',			
				loadChildren: () =>
					import(
						'../components/voucher-log/voucher-log.module'
					).then((m) => m.VoucherLogModule),
			},{
				path: 'perfil-empresa',
				pathMatch: 'full',
				component: PerfilEmpresaComponent
			},
			
		]
	},
	{ path: '**', redirectTo: 'error/403', pathMatch: 'full' }
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})

export class MardomGoAdminRoutingModule { }
